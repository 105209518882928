<template>
  <div class="page-wrapper">
    <div class="h-100 d-flex align-items-center justify-content-center">
      <a-card class="card-wrapper p-0" title="">
        <div class="row">
          <div
            class="
              col-12 col-lg-7
              position-relative
              order-1 order-lg-0
              athletes
            "
          >
            <slot name="leftImage" />
            <!-- img tag -->
          </div>
          <div class="col-12 col-lg-5 order-0 order-lg-1">
            <slot />
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
});
</script>
<style scoped lang="scss">
@import "bootstrap/scss/bootstrap-grid.scss";
.page-wrapper {
  background-image: url(../../assets/images/artboard-ball.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  @include media-breakpoint-up(lg) {
    height: calc(100vh - 64px);
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .card-wrapper {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 4px 4px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    width: auto;
    @include media-breakpoint-up(lg) {
      width: 700px;
    }

    @media (min-width: 601px) {
      :deep(.sat-card-body) {
        background-image: url(../../assets/images/light-blue-pie.svg);
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: 50%;
        @media (min-width: 601px) and (max-width: 1000px) {
          width: 600px;
          background-size: 82%;
        }
      }
    }
    @media screen and (max-width: 600px) {
      :deep(.sat-card-body) {
        background-image: url(../../assets/images/light-blue-pie.svg);
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: bottom left;
        width: 350px;
      }
    }
    .athletes {
      align-content: center;
      text-align: center;
      max-width: 100%;
      height: auto;
      :deep(img) {
        @include media-breakpoint-up(lg) {
          position: absolute;
          bottom: -30px;
          width: 115%;
          left: -45px;
          max-width: unset;
        }
      }
    }
  }
}
</style>
